<template lang="pug">
  .custom-schema-presets-page
    .header.app-header
      h1.nio-h1.text-primary-darker Custom Schema Presets
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .options(:class="{'disabled' : loading}")
      .opt-card(
        :class="[type === 0 ? 'selected' : '']"
        @click="type = 0" 
      )
        .icon 
          nio-icon-framer(
            icon-name="display-quick-start"
            small 
          )
        .option-text
          h4.nio-h4.text-primary-darker Saved Presets
          p.nio-p.text-primary-dark Start a new subscription using your recently used presets
      .opt-card(
        :class="[type === 1 ? 'selected' : '']"
        @click="type = 1" 
      )
        .icon 
          nio-icon-framer(
            icon-name="display-listmanager"
            small 
          )
        .option-text
          h4.nio-h4.text-primary-darker Create New Preset
          p.nio-p.text-primary-darker Select attributes for your new subscription
    NioTabs( 
      v-model="type"
      :tabs="tabs"
    )
      template(
        v-slot:saved
      )
        .no-schema-presets(
          v-if="schemaPresets && schemaPresets.length === 0"
        )
          NioIconFramer(
            icon-name="display-list"
          )
          h3.nio-h3.text-primary-darker You haven't created any schema presets
          NioButton(
            normal-secondary
            @click="seeHow"
          ) See how schema presets work
        .schema-presets(v-else-if="schemaPresets && attributes")
          SchemaPresetList(
            :attributes="attributes"
            :schema-presets="schemaPresets"
            :allow-deactivate="true"
            @deactivatePreset="deactivatePreset($event)"
          )
      template(
        v-slot:new
      )
        NewSchemaPresetPage(
          :attributes="attributes"
          @customPresetCreated="customTypeCreated"
        )
    NioDialog(
      v-model="confirmDeactivateDialog" 
    )
      ConfirmDeactivateDialog(
        @cancel="confirmDeactivateDialog = false"
        @confirm="confirmDeactivatePreset"
      )      
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { replacePropertyRefs } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import SchemaPresetList from '@/shared/components/SchemaPresetList'
import ConfirmDeactivateDialog from './ConfirmDeactivateDialog'
import NewSchemaPresetPage from '../new/NewSchemaPresetPage'
import { getAttributes, getSchemaPresets } from '@/shared/fetchOperations'

export default { 
  components: { SchemaPresetList, ConfirmDeactivateDialog, NewSchemaPresetPage  },
  data: () => ({
    tabs: [
      {
        name: 'saved',
        label: 'Saved Schema Presets'
      },
      {
        name: 'new',
        label: 'Create New Schema Preset'
      }
    ],
    type: 'saved',
    loading: true,
    schemaPresets: null,
    attributes: null,
    confirmDeactivateDialog: false,
    deactivatePresetId: null
  }),
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    async openApiInit() {
      const [attributes, schemaPresets] = await Promise.all([getAttributes(this.$nioOpenApi), getSchemaPresets(this.$nioOpenApi)])
      const rawAttributes = [...attributes]
      this.attributes = attributes.map(attribute => {
        return {
          ...replacePropertyRefs(attribute, rawAttributes)
        }
      })
      this.loading = false
      this.schemaPresets = schemaPresets.filter(schemaPreset => schemaPreset.scope === 'private')
    },
    newSchemaPreset() {		
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 80
        }
      },"*")
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new-schema-preset'
      },"*")
    },
    seeHow()  {
      window.open('https://kb.narrative.io/buyer-studio-presets', '_blank')
    },
    deactivatePreset(presetId) {
      this.deactivatePresetId = presetId
      this.confirmDeactivateDialog = true
    },
    confirmDeactivatePreset() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 80
        }
      },"*")
      this.confirmDeactivateDialog = false
      this.loading = true
      this.$nioOpenApi.delete(`/schema-presets/${this.deactivatePresetId}`).then(res => {
        this.openApiInit()
      })
    },
    customTypeCreated() {
      this.loading = true
      this.type = 0
      this.schemaPresets = null
      this.openApiInit()
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.custom-schema-presets-page
  background-color: $c-white
  padding: 1.5rem
  .header
    display: flex
    justify-content: center
    align-items: flex-end
    position: relative
    margin-bottom: 2.2rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
  .options
    display: flex
    justify-content: space-between
    &.disabled
      pointer-events: none
      cursor: initial
      opacity: 0.5
    & > .opt-card + .opt-card
      margin-left: 1.5rem
    .opt-card
      display: flex
      align-items: center
      background-color: $c-canvas
      border: 2px solid $c-primary-lighter
      border-radius: 12px
      padding: 1rem
      cursor: pointer
      width: 50%
    
      .coming-tag
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
      .icon
        display: flex
        justify-content: center
        align-items: center
        min-width: 4rem
        min-height: 4rem
        margin-right: 1.5rem
        .nio-icon-framer
          background: $c-primary
          border-color: $c-primary
          ::v-deep svg
            path
              stroke: white
    .selected
      border-color: $c-primary
    .disabled
      opacity: 0.5
  .nio-tabs
    ::v-deep .v-tabs
      display: none
    ::v-deep .nio-divider
      display: none
  .no-schema-presets
    margin-top: 24px
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p    
      margin-bottom: 2.5rem
    
</style>
