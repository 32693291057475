import { render, staticRenderFns } from "./CustomSchemaPresetsPage.vue?vue&type=template&id=128a7567&scoped=true&lang=pug&"
import script from "./CustomSchemaPresetsPage.vue?vue&type=script&lang=js&"
export * from "./CustomSchemaPresetsPage.vue?vue&type=script&lang=js&"
import style0 from "./CustomSchemaPresetsPage.vue?vue&type=style&index=0&id=128a7567&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128a7567",
  null
  
)

export default component.exports